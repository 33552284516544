<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-media class="d-flex flex-column" no-body>
        <b-media-aside>
          <b-link>
            <b-img ref="previewEl" rounded :src="profileImage && profileImage !== null
                ? profileImage
                : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
              " height="80" />
          </b-link>
        </b-media-aside>
        <b-media-body class="mt-75 ml-75">
          <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="handleClickProfileImage">
            Upload
            <div style="display: none">
              <b-form-file v-model="profileImage" id="fileUpload" accept="image/*"
                @input="handleFileChange($event, 'profileimage')" />
            </div>
          </b-button>
          <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75" @click="removeprofileImage">
            Reset
          </b-button>
        </b-media-body>
      </b-media>
      <b-form @submit.prevent="submitForm">
        <b-row>

          <b-col md="4">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Name">
                <label>Name</label>
                <label style="color: red !important">*</label>
                <b-form-input v-model="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Name" rules="required" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>

              <label>Surname</label>
              <b-form-input v-model="surname" placeholder="Enter Name" />

            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Hather / Husband Name">
                <label>Father/Husband Name</label>
                <label style="color: red !important">*</label>
                <b-form-input v-model="fathername" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Name" rules="required" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Email</label>
              <b-form-input v-model="email" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Email" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Mobile</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Mobile">
                <b-form-input v-model="mobile" :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="Enter Mobile" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Gender</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Gender">
                <v-select v-model="gender" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  placeholder="None" :options="['Male', 'Female']" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="ifEdit == true">
            <b-form-group>
              <label>User name (ID)</label>
              <b-form-input v-model="username" disabled :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Username" />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="ifEdit == false">
            <b-form-group>
              <label>Password</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required|password" name="Password">
                <b-input-group class="input-group-merge">
                  <b-form-input v-model="password" placeholder="Enter Password" :type="passwordFieldTypeNew" />

                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Birth Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Birth Date">
                <flat-pickr v-model="birthdate" class="form-control" :config="{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                  maxDate: new Date(),
                }" style="background-color: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Marital Status</label>
              <v-select v-model="maritalstatus" placeholder="None" :options="['Married', 'Unmaried']" />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="maritalstatus == 'Married'">
            <b-form-group>
              <label>Anniversary Date</label>
              <flat-pickr v-model="anniversarydate" placeholder="Select Date" class="form-control" :config="{
                dateFormat: 'd/m/Y',
                maxDate: new Date(),
              }" style="background-color: transparent" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Joining Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Joining Date">
                <flat-pickr v-model="joiningdate" placeholder="Select Date" class="form-control" :config="{
                  dateFormat: 'd/m/Y',
                }" style="background-color: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="ifEdit">
            <b-form-group>
              <label>Resign Date</label>
              <flat-pickr v-model="resigndate" placeholder="Select Date" class="form-control" :config="{
                dateFormat: 'd/m/Y',
              }" style="background-color: transparent" />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Current Address</span>
            <!-- <b-card-code title="Current Address" no-body>
              <b-col></b-col>
            </b-card-code> -->
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 1</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Address Line 1">
                <b-form-input v-model="curaddressline1" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 1" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 2</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Address Line 2">
                <b-form-input v-model="curaddressline2" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 2" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required|digits:6" name="Pin Code">
                <b-form-input v-model="curpincode" maxlength="6" @input="clickPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" type="number" placeholder="Enter Pin Code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>City</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="City">
                <v-select v-model="curcity" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="City"
                  :options="curcityOption" @input="clickArea($event)" placeholder="None" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Area</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Area">
                <v-select v-model="curarea" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="PostOfficeName"
                  :options="curareaOption" placeholder="None" @input="handleCurPincode()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Taluka</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Taluka">
                <b-form-input v-model="curtaluka" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Taluka" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>State</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="State">
                <v-select v-model="curstate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="stateOptions"
                  placeholder="None" label="State" @input="handleCurCity()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Permanent Address</span>
            <!-- <b-card-code title="Current Address" no-body>
              <b-col></b-col>
            </b-card-code> -->
            <b-form-checkbox @input="handlePermanent" class="ml-2" inline>
              Same As Current Address
            </b-form-checkbox>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 1</label>
              <b-form-input v-model="peraddressline1" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 1" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 2</label>
              <b-form-input v-model="peraddressline2" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 2" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <validation-provider #default="{ errors }" rules="digits:6" name="pin Code">
                <b-form-input v-model="perpincode" maxlength="6" @input="clickPerPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" type="number" placeholder="Enter Pin Code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>City</label>
              <v-select v-model="percity" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="percityOption"
                @input="clickPerArea($event)" label="City" placeholder="None" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Area</label>
              <v-select v-model="perarea" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perareaOption"
                placeholder="None" @input="handlePincode()" label="PostOfficeName" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Taluka</label>
              <b-form-input v-model="pertaluka" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Taluka" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>State</label>
              <v-select v-model="perstate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="stateOptions"
                placeholder="None" label="State" @input="handleCity()" />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-code title="" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Bank</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Bank">
                <b-form-input v-model="bankname" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Bank" rules="required" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Branch</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Branch">
                <b-form-input v-model="bankbranch" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Branch" rules="required" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Account No</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Account No">
                <b-form-input v-model="bankaccno" type="number" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Account No" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>IFSC Code</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="IFSC Code">
                <b-form-input v-model="ifsccode" maxlength="11" @input="validateIFSCCodeClick($event)"
                  placeholder="Enter IFSC Code" />
                <small v-if="ifsccodeValidate == false" class="text-danger">Enter Valid IFSC Code</small>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar No</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Aadhar No">
                <b-form-input v-model="aadharcardno" maxlength="14" @input="validateAadharClick($event)"
                  placeholder="Enter Aadhar No" />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="aadharcardnoValidate == false" class="text-danger">Enter Valid Aadhar No</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pancard No</label>
              <!-- <label style="color: red !important">*</label> -->
              <!-- <validation-provider #default="{ errors }" rules="required" name="Aadhar No"> -->
              <b-form-input v-model="panno" maxlength="10" @input="validatePanClick($event)"
                placeholder="Enter Pan No" />
              <small v-if="pannoValidate == false" class="text-danger">Enter Valid Pan No</small>
              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              <!-- </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Work Experience</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="work Exprience">
                <b-form-input v-model="workexperience" placeholder="Enter Work Experience" />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Education</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Education">
                <b-form-input v-model="education" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Education" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Licence No</label>

              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Licence No">
                <b-form-input v-model="licence" @input="validateLicenceClick($event)" placeholder="Enter Licence No" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              <!-- </validation-provider> -->
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Licence Isuue Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Licence Isuue Date">
                <flat-pickr v-model="licenceissuedate" placeholder="Select Date" class="form-control" :config="{
                  dateFormat: 'd/m/Y',
                }" style="background-color: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Licence Expiry Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Licence Expiry Date">
                <flat-pickr v-model="licenceexpirydate" placeholder="Select Date" class="form-control" :config="{
                  dateFormat: 'd/m/Y',
                }" style="background-color: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Aadhar Card Front Side">
                <label>Aadhar Card Front Side</label>
                <label style="color: red !important">*</label>
                <b-form-file v-model="documents.aadharfrontside" placeholder="Select Photo"
                  drop-placeholder="Drop file here..." accept="image/*"
                  @input="handleFile($event, 'aadharfrontside', 'driver')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="documents.aadharfrontside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Aadhar Card Back Side">
                <label>Aadhar Card Back Side</label>
                <label style="color: red !important">*</label>
                <b-form-file v-model="documents.aadharbackside" placeholder="Select Photo"
                  drop-placeholder="Drop file here..." accept="image/*"
                  @input="handleFile($event, 'aadharbackside', 'driver')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="documents.aadharbackside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Licence">
                <label>Upload Letest Marksheet/Degree</label>
                <label style="color: red !important">*</label>
                <b-form-file v-model="documents.marksheet" placeholder="Select Photo"
                  drop-placeholder="Drop file here..." accept="image/*"
                  @input="handleFile($event, 'marksheet', 'driver')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="documents.marksheet" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Licence">
                <label>Licence Photo</label>
                <label style="color: red !important">*</label>
                <b-form-file v-model="documents.licencephoto" placeholder="Select Photo"
                  drop-placeholder="Drop file here..." accept="image/*"
                  @input="handleFile($event, 'licencephoto', 'driver')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="documents.licencephoto" />
          </b-col>

          <b-col cols="12" class="mt-1">
            <b-button variant="primary" type="submit" :disabled="flag || submitDisable"
              @click.prevent="submitForm($event)" class="mr-4">
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import BCardCode from "@core/components/b-card-code";
import Attachment from "../../../../components/Attechment.vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from '@/components/axios';
import moment from "moment";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import TargetVue from "@/views/crm/target/Target.vue";

export default {
  components: {
    Attachment,
    BFormCheckbox,
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,

    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },

  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      name: "",
      surname: "",
      fathername: "",
      email: "",
      mobile: "",
      gender: "",
      birthdate: moment(new Date()).format("DD/MM/yyyy"),
      joiningdate: moment(new Date()).format("DD/MM/yyyy"),
      resigndate: null,
      profileImage: "",
      curaddressline1: "",
      curaddressline2: "",
      curarea: "",
      curtaluka: "",
      curstate: "",
      curcity: "",
      curpincode: "",
      peraddressline1: "",
      peraddressline2: "",
      perarea: "",
      pertaluka: "",
      perstate: "",
      percity: "",
      perpincode: "",
      licence: "",
      bankname: "",
      bankbranch: "",
      bankaccno: "",
      ifsccode: "",
      aadharcardno: "",

      maritalstatus: "",
      anniversarydate: "",
      workexperience: "",
      education: "",
      licenceissuedate: "",
      licenceexpirydate: "",
      panno: "",

      aadharcardnoValidate: true,
      ifsccodeValidate: true,
      password: "",

      curcityOption: [],
      curareaOption: [],
      percityOption: [],
      perareaOption: [],
      ifEdit: "",
      id: "",
      fromCurState: "",
      fromState: "",
      stateOptions: [],
      issitemanager: "",
      documents: {
        marksheet: "",
        aadharfrontside: "",
        aadharbackside: "",
        licencephoto: "",
      },
      testemail: "",
      testaadhar: "",
      testlicence: "",
      testpanno: "",
      pannoValidate: true,
      flag: false,
      submitDisable: false,
      passwordFieldTypeNew: "password",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Driver") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/master/driver");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/master/driver");
          }
        }
      });
    }
    this.getState();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },

  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    validateAadharClick(e) {
      const regex = new RegExp(/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/);
      this.aadharcardno.length == 4
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      this.aadharcardno.length == 9
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      if (regex.test(this.aadharcardno) == true) {
        this.aadharcardnoValidate = true;
      } else {
        this.aadharcardnoValidate = false;
      }
    },
    validatePanClick() {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }
      this.pannoValidate = this.panno == "" ? true : this.pannoValidate;
    },
    validateLicenceClick(e) {
      this.licence = this.licence.toUpperCase();
    },
    handlePermanent(e) {
      if (e == true) {
        this.perarea = this.curarea;
        this.perstate = this.curstate;
        this.percity = this.curcity;
        this.pertaluka = this.curtaluka;
        this.perpincode = this.curpincode;
        this.peraddressline1 = this.curaddressline1;
        this.peraddressline2 = this.curaddressline2;
      } else {
        this.perarea = "";
        this.perstate = "";
        this.percity = "";
        this.pertaluka = "";
        this.perpincode = "";
        this.peraddressline1 = "";
        this.peraddressline2 = "";
      }
    },
    validateIFSCCodeClick(e) {
      const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      this.ifsccode = this.ifsccode.toUpperCase();
      if (regex.test(this.ifsccode) == true) {
        this.ifsccodeValidate = true;
      } else {
        this.ifsccodeValidate = false;
      }
    },
    removeprofileImage() {
      this.profileImage = null;
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    handleClickProfileImage() {
      document.getElementById("fileUpload").click();
    },
    getEditItems(item) {
      item.map((item) => {
        this.name = item.name;
        this.surname = item.surname;
        this.username = item.username;
        this.fathername = item.fathername;
        this.email = item.email;
        this.testemail = item.email;
        this.mobile = item.mobile;
        this.gender = item.gender;
        this.birthdate = item.birthdate;
        this.joiningdate = item.joiningdate;
        this.resigndate =
          item.resigndate == "00/00/0000" || item.resigndate == null
            ? null
            : item.resigndate.split("-").reverse().join("/");
        this.profileImage = item.profile_image;
        this.curaddressline1 = item.curaddressline1;
        this.curaddressline2 = item.curaddressline2;
        this.curarea = item.curarea;
        this.curtaluka = item.curtaluka;
        this.curstate = item.curstate;
        this.curcity = item.curcity;
        this.curpincode = item.curpincode;
        this.peraddressline1 = item.peraddressline1;
        this.peraddressline2 = item.peraddressline2;
        this.perarea = item.perarea;
        this.pertaluka = item.pertaluka;
        this.perstate = item.perstate;
        this.percity = item.percity;
        this.perpincode = item.perpincode;
        this.licence = item.licence;
        this.testlicence = item.licence;
        this.bankname = item.bankname;
        this.bankbranch = item.bankbranch;
        this.bankaccno = item.bankaccno;
        this.ifsccode = item.ifsccode;
        this.aadharcardno = item.aadharcardno;
        this.maritalstatus = item.maritalstatus;
        this.anniversarydate = item.anniversarydate;
        this.workexperience = item.experience;
        this.education = item.education;
        this.licenceissuedate = item.licenceissuedate;
        this.licenceexpirydate = item.licenceexpirydate;
        this.panno = item.panno;
        this.panno = item.panno;
        this.testpanno = item.panno;
        this.testaadhar = item.aadharcardno;
        this.documents.aadharfrontside = item.aadharfrontside;
        this.documents.aadharbackside = item.aadharbackside;
        this.documents.licencephoto = item.licencephoto;
        this.documents.marksheet = item.marksheet;
      });
      this.location();
      // this.clickPincode()
      // this.clickPerPincode()
    },
    location() {
      this.handleCity();
      this.handleCurCity();
      this.handleCurArea();
      this.handleArea();
    },

    async handleFileChange(e, type) {
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.profileImage = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"));
    },
    handlePincode() {
      if (this.fromState == true) {
        this.perpincode = this.perarea.Pincode;
      }
    },
    handleCurPincode() {
      if (this.fromCurState == true) {
        this.curpincode = this.curarea.Pincode;
      }
    },
    async handleCurCity() {
      this.curareaOption = [];
      this.curcityOption = [];
      this.fromCurState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.curstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curcityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleCity() {
      this.perareaOption = [];
      this.percityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.perstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.percityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.percity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.perareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickPerArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }
      // this.perareaOption = []
      // this.perStateData.map((item) => {
      //   if (item.City == e) {
      //     this.perareaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPerPincode() {
      this.percity = "";
      this.perarea = "";
      this.perstate = "";
      this.perareaOption = [];
      this.percityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.perpincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.perpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.percityOption = [];
            this.perareaOption = response.data.data;
            this.perStateData = response.data.data;
            this.perstate = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.percityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async handleCurArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.curcity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromCurState == true) {
        this.handleCurArea();
      }
      // this.curareaOption = []
      // this.curStateData.map((item) => {
      //   if (item.City == e) {
      //     this.curareaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPincode() {
      this.curcity = "";
      this.curarea = "";
      this.curstate = "";
      this.curareaOption = [];
      this.curcityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.curpincode)) {
        this.fromCurState = false;

        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.curpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.curcityOption = [];
            this.curStateData = response.data.data;
            this.curareaOption = response.data.data;
            this.curstate = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.curcityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async handleFile(e, name, type) {
      this.flag = true;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestoption = {
        method: "POST",
        headers: {
          "Contant-type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestoption)
        .then((response) => {
          this.documents[name] = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.flag = false;
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.$router.push("/crm/master/driver");
    },
    submitForm(e) {
      const data = {
        name: this.name,
        fathername: this.fathername,
        surname: this.surname,
        email: this.email,
        mobile: this.mobile,
        gender: this.gender,
        birthdate: this.birthdate,
        password: this.password,
        joiningdate: this.joiningdate,
        resigndate: this.resigndate,
        profile_image: this.profileImage,
        curaddressline1: this.curaddressline1,
        curaddressline2: this.curaddressline2,
        curarea: this.curarea.PostOfficeName,
        curtaluka: this.curtaluka,
        curcity: this.curcity.City,
        curpincode: this.curpincode,
        curstate: this.curstate.State,
        peraddressline1: this.peraddressline1,
        peraddressline2: this.peraddressline2,
        perarea: this.perarea ? this.perarea.PostOfficeName : "",
        pertaluka: this.pertaluka,
        percity: this.percity ? this.percity.City : "",
        perstate: this.perstate ? this.perstate.State : "",
        perpincode: this.perpincode,
        licence: this.licence,
        bankname: this.bankname,
        bankbranch: this.bankbranch,
        bankaccno: this.bankaccno,
        ifsccode: this.ifsccode,
        aadharcardno: this.aadharcardno,
        aadharfrontside: this.documents.aadharfrontside,
        aadharbackside: this.documents.aadharbackside,
        licencephoto: this.documents.licencephoto,
        marksheet: this.documents.marksheet,
        panno: this.panno,
        maritalstatus: this.maritalstatus,
        anniversarydate: this.anniversarydate,
        experience: this.workexperience,
        education: this.education,
        licenceissuedate: this.licenceissuedate,
        licenceexpirydate: this.licenceexpirydate,
        panno: this.panno,
      };
      if (this.ifEdit == true) {
        delete data.password;
      }
      if (this.testemail == data.email) {
        delete data.email;
      }
      if (this.testaadhar == data.aadharcardno) {
        delete data.aadharcardno;
      }
      if (this.testlicence == data.licence) {
        delete data.licence;
      }
      if (this.testpanno == data.panno) {
        delete data.panno;
      }
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (
          success == false ||
          this.aadharcardnoValidate == false ||
          this.ifsccodeValidate == false ||
          this.pannoValidate == false
        ) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (
          success &&
          this.aadharcardnoValidate == true &&
          this.ifsccodeValidate == true &&
          this.pannoValidate == true
        ) {
          this.submitDisable = true;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${this.baseApi}/driver/${this.$route.params.id}`
              : `${this.baseApi}/driver`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              this.submitDisable = false;

              this.$router.push("/master/crm/driver");
              this.$swal({
                title: "Submited",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                    ? `${response.data.success}`
                    : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.submitDisable = false;

              let email, name, aadharcard, licence, message;
              const code = error.toString().includes("409");
              if (code) {
                email = error.response.data.message.original.email
                  ? error.response.data.message.original.email[0]
                  : "";
                name = error.response.data.message.original.name
                  ? error.response.data.message.original.name[0]
                  : "";
                aadharcard = error.response.data.message.original.aadharcardno
                  ? error.response.data.message.original.aadharcardno[0]
                  : "";
                licence = error.response.data.message.original.panno
                  ? error.response.data.message.original.panno[0]
                  : "";
                message = email + " " + aadharcard + " " + licence + " " + name;
              }

              this.$swal({
                title: "Error!",
                text: code ? message : error.response.data.message,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
